import produce from 'immer';
import { selectReceivedMessages } from '../selectors';
import APIServices from '../../APIServices';
import utilsServices from '../../utils';

/**
 * Initial state of the receivedMessage feacture
 */
const initialState = {
    /**
     * Track  the status of the request
     */
    status: 'void',
    /**
     * Data when request is ok
     */
    data: null,
    /**
     * Error when request failed
     */
    error: null,
}
// Les noms des actions
const FETCHING = 'receivedMessage/fetching'
const RESOLVED = 'receivedMessage/resolved'
const REJECTED = 'receivedMessage/rejected'

/**
 * the request is in progress
 * @returns return status
 */
const receivedMessageFetching = () => ({ type: FETCHING })

/**
 * the request is ok
 * @param data data of query
 * @returns return status
 */
const receivedMessageResolved = (data: any) => ({ type: RESOLVED, payload: data });

/**
 * the request failed
 * @param error error message
 * @returns return status
 */
const receivedMessageRejected = (error: any) => ({ type: REJECTED, payload: error })

/**
 * This function is asynchrone action
 * @param store redux store parameter
 * @returns Return nothing when statu is pending or updating to avoid miltiple execution
 */
export async function _fetchOrUpdateReceivedMessage(store: any, params: any) {

    const status = selectReceivedMessages(store.getState()).status;
    //if the request is already in progress
    if (status === 'pending' || status === 'updating') {
        return;
    }
    // You can change the state by sending actions with store.dispatch()
    // here we indicate that the request is in progress
    store.dispatch(receivedMessageFetching())
    try {

        const data: any = await APIServices.GetReceivedMessage(params);
        let messages = data.Messages.reduce(
            (msg: any, data: any) => {
                return utilsServices.getDetailMessages(msg, data);
            }
            , []
        );
        store.dispatch(receivedMessageResolved({ Messages: messages, TotalItems: data.TotalItems }));

    } catch (error) {
        store.dispatch(receivedMessageRejected(error))
    }
}

export default function receivedMessagesReducer(state = initialState, action: any) {
    // on utilise immer pour changer le state
    return produce(state, (draft) => {
        // on fait un switch sur le type de l'action
        switch (action.type) {
            // si l'action est de type FETCHING
            case FETCHING: {
                // si le statut est void
                if (draft.status === 'void') {
                    // on passe en pending
                    draft.status = 'pending'
                    return
                }
                // si le statut est rejected
                if (draft.status === 'rejected') {
                    // on supprime l'erreur et on passe en pending
                    draft.error = null
                    draft.status = 'pending'
                    return
                }
                // si le statut est resolved
                if (draft.status === 'resolved') {
                    // on passe en updating (requête en cours mais des données sont déjà présentent)
                    draft.status = 'updating'
                    return
                }
                // sinon l'action est ignorée
                return
            }
            // si l'action est de type RESOLVED
            case RESOLVED: {
                // si la requête est en cours
                if (draft.status === 'pending' || draft.status === 'updating') {
                    // on passe en resolved et on sauvegarde les données
                    draft.data = action.payload
                    draft.status = 'resolved'
                    return
                }
                // sinon l'action est ignorée
                return
            }
            // si l'action est de type REJECTED
            case REJECTED: {
                // si la requête est en cours
                if (draft.status === 'pending' || draft.status === 'updating') {
                    // on passe en rejected, on sauvegarde l'erreur et on supprime les données
                    draft.status = 'rejected'
                    draft.error = action.payload
                    draft.data = null
                    return
                }
                // sinon l'action est ignorée
                return
            }
            // Sinon (action invalide ou initialisation)
            default:
                // on ne fait rien (retourne le state sans modifications)
                return
        }
    })
}