import loader from "../../assets/images/siteicons/apple-icon-76x76.png";

const GlobalLoad = ({ imgLoading = true, title = "" }: { imgLoading?: boolean, title: String }) => {

    return (
        <div className="idn-loader-container">
            {imgLoading && <img src={loader} alt="" />}
            <div id="idn-app-version">{title}</div>
            <div className="idn-dots">
                <div className="idn-dot idn-dot1"></div>
                <div className="idn-dot idn-dot2"></div>
                <div className="idn-dot idn-dot3"></div>
            </div>
        </div>
    );
};

export default GlobalLoad;