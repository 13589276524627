const SET_TOTAL_MESSAGES = "totalItemsMessages/set";

export const setTotalItems = (total: any) => ({
    type: SET_TOTAL_MESSAGES,
    payload: total
})

export default function totalItemsReducer(state = 0, action: any) {
    if (action.type === SET_TOTAL_MESSAGES) {
        return action.payload
    }

    return state;
}