import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import Backend from 'i18next-http-backend';

i18n
    .use(Backend)
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({

        /* default language when load the website in browser */
        //lng: "fr",
        /* When react i18next not finding any language to as default in borwser */
        fallbackLng: "en-US",
        /* debugger For Development environment */
        debug: true,
        // ns: ["translations"],
        // defaultNS: "translations",
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        react: {
            //wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default',
            useSuspense: true //   <---- this will do the magic
        }
    })

export default i18n;