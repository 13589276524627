import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useState } from "react";

import PageContent from "../content/PageContent";
import { useWindowSize, useDisplayMessage } from "../../services/hooks";
import { useUnReadMessageReceived } from "../../services/hooks";



const InfoMessage = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const { windowSize } = useWindowSize();
    const { _setMessage } = useDisplayMessage();
    const { TotalItems } = useUnReadMessageReceived();

    const initMessage = () => {
        _setMessage({});
    }

    return (
        <PageContent
            title={t("Communication.MyCom")}
            show={show}
            setShow={setShow}
            className={show ? "idn-page-content" : "idn-link-content-hide"}
            showIcon={windowSize.width && windowSize.width <= 800 && windowSize.width != 0 ? true : false}
        ><section>
                {TotalItems && TotalItems > 0 ?
                    <p>{t("Communication.UnReadMsg", { number: TotalItems })}</p>
                    :
                    <p>{t("Communication.CaughtMsg")}</p>
                }
                <Link to="/communication/message"
                    onClick={() => {
                        initMessage()
                    }}
                >{t("Communication.ClickHere")}</Link>
            </section>
            <br />
        </PageContent>
    );
}

export default InfoMessage;