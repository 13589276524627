import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { useUpdateTitle } from "../../services/hooks";
import { useEffect } from "react";
export default function Error404() {
    const { setDocumentTitle } = useUpdateTitle();

    const { t } = useTranslation();
    const { accounts } = useMsal();
    useEffect(() => {
        setDocumentTitle("Error 404");
    });
    return (
        <div className="idn-error404 mb-5">
            <div className="idn-error404-page">
                <h1 className="idn-error404-page__header mb-5">
                    404
                </h1>
                <p className="idn-error404-page__title">
                    {t("ErrorLoading.PageNotFound")}
                </p>
                <p className="idn-error404-page__desc mb-5">
                    {t("ErrorLoading.LabelPageNotFound")} : {t("subscribeForm.eliorApiErrorSupportPhoneNumber")}
                </p>
                <Link to={accounts.length === 0 ? "/" : "/applications"} className="idn-button idn-width-full"  >
                    {t("subscribeForm.returnHome")}
                </Link>
            </div>
        </div>

    )
}