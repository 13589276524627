import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'

const useGaTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        ReactGA.initialize(`${process.env["REACT_APP_GA"]}`);
        TagManager.initialize({
            gtmId: `${process.env["REACT_APP_GTM"]}`,
        });
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (initialized) {
            console.log(location.pathname)
            console.log(location.search)
            ReactGA.send("pageview");
        }
    }, [initialized, location]);
};

export default useGaTracker;