import styled from "styled-components";
import { BsXLg } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { stripHtml } from 'string-strip-html';
import { useTranslation } from "react-i18next";

import { useUnReadMessageReceived } from "../../services/hooks";
import { useDisplayMessage } from "../../services/hooks";
import UrgentSign from "../Buttons/UrgentSign";

const Close = styled.div` 
    cursor:pointer;       
`;

const MessageContent = styled.div`  
    height:100%;
    width:99%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;   
    gap:10px;   
`;

const Message = styled.div`
    cursor:pointer;
`;

const NewMessage = () => {
    const { Messages, TotalItems, ReadMessage } = useUnReadMessageReceived();
    const [show, setShow] = useState(true);
    const { _setMessage } = useDisplayMessage();
    const history = useHistory();

    const { t } = useTranslation();

    const getLabelTranslate = (msg: any) => {
        let TimeLabel = JSON.parse(t("date.date").replaceAll("'", "\""));
        return msg.Time === 0 ? TimeLabel[0] : `${msg.Time} ${TimeLabel[msg.DurationLabel]}`;
    }

    return (
        <>
            {
                show && Messages && TotalItems > 0 ?
                    <section className="message-box">
                        <MessageContent>
                            {Messages.slice(0, 2).map((msg: any) =>
                                <Message
                                    key={`${msg.Title}${msg.MessageId}`}
                                    onClick={() => {
                                        _setMessage(msg);
                                        ReadMessage(msg);
                                        history.push("/communication/message");

                                    }} className="block">
                                    <div className="message-box__title">
                                        {msg.IsUrgent ? <UrgentSign /> : ""} {stripHtml(msg.Title).result}
                                    </div>
                                    <div className="message-box__content">
                                        {stripHtml(msg["Text Details"].find((m: any) => m["Text Position"] === 1).Text).result}
                                    </div>
                                    <div className="message-box__time">
                                        {`${getLabelTranslate(msg)}`}
                                    </div>
                                </Message>
                            )}
                        </MessageContent>
                        <Close onClick={() => { setShow(false) }}>
                            <BsXLg className="idn-color--primary" />
                        </Close>
                    </section>
                    : ""
            }
        </>

    );
}

export default NewMessage;