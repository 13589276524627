
import { Tooltip as TooltipEl, OverlayTrigger } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

const Tooltip = ({ children, title, position, trigger = ['hover', 'focus', 'click'] }: { children: any, title: string, position: any, trigger?: any }) => {

    return (
        isMobile
            ? children :
            <OverlayTrigger
                trigger={['hover', 'focus', 'click']}
                placement={position}
                overlay={<TooltipEl id={`button-tooltip`}  >
                    {title}
                </TooltipEl>}
            >
                {children}
            </OverlayTrigger>
    );
}

export default Tooltip;

