
export class EditTranslationModel {

    public FieldId: Number;
    public FieldValue: string;

    constructor(datas: any) {
        this.FieldId = datas.fieldId;
        this.FieldValue = datas.fieldValue;
    }
}