
import { Helmet , HelmetProvider } from 'react-helmet-async';

export default function NoIndex() {
    return (
        <HelmetProvider>
            <Helmet>
                <link rel="canonical" href={window.location.href} />
                <meta name="robots" content="noindex, noFollow, NoArchive, NoSnippet, NoImageIndex" />
            </Helmet>
        </HelmetProvider>
    )
}