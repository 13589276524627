import HeaderItem from "../Header/HeaderItem";
import styled from "styled-components";
import { useWindowSize } from "../../services/hooks";


const PageContent = (
    { children, title, show, setShow, className, showIcon = false, filterBar, buttonBack }:
        {
            children: any, title: any, show?: boolean,
            setShow?: any, className?: any, showIcon?: boolean,
            filterBar?: any, buttonBack?: any
        }
) => {

    const { windowSize } = useWindowSize();

    return (
        <div style={{ textAlign: "left" }} className={"idn-card-list"}>
            <HeaderItem
                title={title}
                setShow={setShow}
                show={show}
                showIcon={showIcon}
                className={!filterBar ? "idn-page-title--marginBottom" : "idn-page-title--marginBottom-zero"}
                buttonBack={buttonBack}
            />
            {windowSize.width && windowSize.width <= 800 && windowSize.width != 0 ? (show ? filterBar : "") : filterBar}
            <div style={{ position: "relative" }} className={`${className}`}>
                {children}
            </div>
        </div>
    );
};

export default PageContent;
