import Authentification from "../../components/Authentification";
import ApplicationList from "../../components/Application/ApplicationList";
import QuickLinkList from "../../components/QuickLink/QuickLinkList";
import styled from "styled-components";
import NewMessage from "../../components/Message/NewMessage";
import NoIndex from "../../components/Meta/NoIndex";
import { useUpdateTitle } from "../../services/hooks";
import { useEffect, useRef, useState } from "react";
import InfoMessage from "../../components/Message/InfoMessage";
import UserEntityFileList from "../../components/EntityFileUpload/UserEntityFileList";


const Home = () => {

  const { setDocumentTitle } = useUpdateTitle();
  const [fileSectionWidth, setFileSectionWidth] = useState<number | undefined>(0);
  const divfile = useRef<HTMLDivElement | null>();

  useEffect(() => {
    setDocumentTitle("HomePage | Welcome");
    setFileSectionWidth(divfile?.current?.clientHeight);   
  })

  
  useEffect(() => {
    if(fileSectionWidth)
      console.log(fileSectionWidth)
  },[fileSectionWidth])



  return (

    <Authentification>
      <NoIndex />
      <div className="home">
        <section className="idn-section">
          <section className="idn-app-content--width">
            <NewMessage />
          </section>
          <section className="PageContent--flex IdnPageContent--minheight homeContent">
            <section className="idn-app-content--width idn-app-content--mobile-minheight">
              <div>
                <ApplicationList />
              </div>
              <div ref={(el) => {divfile.current = el;}}>
                <UserEntityFileList />
              </div>
            </section>
            <section className="idn-link-content">
              <InfoMessage />
              <QuickLinkList />
            </section>
          </section>
        </section>
      </div>

    </Authentification>
  );
};

export default Home;
