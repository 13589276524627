import { useTranslation } from "react-i18next";
import PageContent from "../content/PageContent";
import { useQuickLink } from "../../services/hooks";
import QuickLink from "./QuickLink";
import LoaderContent from "../Load/LoaderContent";
import { Fragment, useState } from "react";
import { useWindowSize } from "../../services/hooks";
import ErrorLoading from "../Errors/ErrorLoading";



const QuickLinkList = () => {
    const { quickLinks, quickLinkCategory, isLoading, isError } = useQuickLink();
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const { windowSize } = useWindowSize();

    return (
        <PageContent
            title={t("header.btnQuickLink")}
            show={show}
            setShow={setShow}
            className={show ? "idn-page-content IdnPageContent--overflow-2" : "idn-link-content-hide  IdnPageContent--overflow-2"}
            showIcon={windowSize.width && windowSize.width <= 800 && windowSize.width != 0 ? true : false}
        >
            <section >
                {isLoading && <LoaderContent />}
                {isError
                    ? <ErrorLoading />
                    : <ul className="list-group list-group-flush">
                        {
                            quickLinks &&
                            quickLinkCategory.map((quickLinkCategory: any, i: number) => (
                                <QuickLink
                                    cat={quickLinkCategory}
                                    quickLinks={quickLinks}
                                    key={`${quickLinkCategory}-${i}`}
                                />
                            ))
                        }
                    </ul>
                }
            </section>

        </PageContent>
    );
}

export default QuickLinkList;