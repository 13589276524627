import React, { useContext } from "react";
import { GetStatusAppContext } from "../services/context";
import { MsalAuthenticationTemplate, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import MaintenancePage from "./Errors/MaintenancePage";

const Authentification = ({ children }: { children: any }) => {
    const { data, _isLoadingSatusApp } = useContext(GetStatusAppContext);


    return (
        <React.Fragment>

            {
                !_isLoadingSatusApp ?
                    data && data.displayMaintenancePage ?
                        <MaintenancePage date={data.activDate} /> :
                        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                            {children}
                        </MsalAuthenticationTemplate> : ""
            }

        </React.Fragment>
    );
};

export default Authentification;
