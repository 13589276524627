import produce from 'immer';
import { selectunReadMessages } from '../selectors';
import APIServices from '../../APIServices';
import utilsServices from '../../utils';

/**
 * Initial state of the unReadMessage feacture
 */
const initialState = {
    /**
     * Track  the status of the request
     */
    status: 'void',
    /**
     * Data when request is ok
     */
    data: null,
    /**
     * Error when request failed
     */
    error: null,
}
// Les noms des actions
const SET_UNREAD_MESSAGE = 'unReadMessage/fetching';

/**
 * This function is asynchrone action
 * @param store redux store parameter
 * @returns Return nothing when statu is pending or updating to avoid miltiple execution
 */
export const setUnReadMessage = (message: {}, unReadMessageNumber: number) => ({
    type: SET_UNREAD_MESSAGE,
    payload: { Messages: message, TotalItems: unReadMessageNumber }
});

export default function unReadMessagesReducer(state = initialState, action: any) {
    if (action.type === SET_UNREAD_MESSAGE) {
        return action.payload;
    }
    return state;
}