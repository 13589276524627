import { useTranslation } from "react-i18next";

const HeaderProfile = (
    props: {
        title?: any,
        className?: any,
        buttonBack?: any,
    }
) => {

    const { t } = useTranslation();
    const _title = props.title ? props.title : t("ProfileUser.pageTitle");

    return (
        <div id="idn-page-header">
            <h1 className={`idn-page-title ${props.className}`}>
                {props.buttonBack}
                {_title}
            </h1>
        </div>
    )
}

export default HeaderProfile;