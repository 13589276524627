export const BASE_URL = process.env.REACT_APP_ELIOR_AZURE_FUNCTION + "api";
const API_KEY = process.env.REACT_APP_ELIOR_AZURE_FUNCTION_KEY;

export const Config = {

  EliorLoginPage: "https://eliorid.elior.eu/adfs/ls/?client-request-id=d3cdd268-aa57-4077-a8f1-519dac62e69d&wa=wsignin1.0&wtrealm=urn%3afederation%3aMicrosoftOnline",
  EliorAPI: {
    GetUserById: BASE_URL + "/GetMDMUserByID",
    InitializeUserAccount: BASE_URL + "/InitializeUserAccount",
    CheckIfAADUserAlreadyExist: BASE_URL + "/CheckIfAADUserAlreadyExist",
    CreateAADaccountAsync: BASE_URL + "/CreateAADaccountAsync",
    GetUserApplications: BASE_URL + "/GetUserApplications",
    GetUserAD: BASE_URL + "/GetUserAD",
    UpdateUserAD: BASE_URL + "/UpdateUserAD",
    InitializeModifyUserAccount: BASE_URL + "/InitializeModifyUserAccount",
    PostUpdateRetryCheckDOB: BASE_URL + "/PostUpdateRetryCheckDOB",
    GetRetrieveRetryCheckDOB: BASE_URL + "/GetRetrieveRetryCheck",
    GetUserQuickLinks: BASE_URL + "/GetUserQuickLinks",
    PostSendDetailsToHr: BASE_URL + "/PostSendDetailsToHr",
    GetAllQuickLink: BASE_URL + "/getAllQuickLink",
    GetAllApplication: BASE_URL + "/getAllApplication",
    CreateUpdateMultipleQuicklink: BASE_URL + "/createUpdateMultipleQuickLinks",
    CreateUpdateQuickLink: BASE_URL + "/createUpdateQuickLink",
    CreateUpdateApplication: BASE_URL + "/CreateUpdateApplication",
    GetAllAudience: BASE_URL + "/getAudience",
    GetAllAudienceType: BASE_URL + "/getAudienceType",
    CreateOrUpdateMessages: BASE_URL + "/CreateOrUpdateMessages",
    SendMessage: BASE_URL + "/SendMessage",
    GetMessages: BASE_URL + "/Messages",
    DeleteMessages: BASE_URL + "/DeleteMessages",
    GetUserRights: BASE_URL + "/GetUserRights",
    MessagesSentEmp: BASE_URL + "/MessagesSentEmp",
    ReadMessage: BASE_URL + "/ReadMessage",
    GetAudienceCount: BASE_URL + "/AudienceCount",
    GetAllCategories: BASE_URL + "/GetAllCategories",
    AddUpdateCategory: BASE_URL + "/AddUpdateCategory",
    GetThemes: BASE_URL + "/GetThemes",
    GetDashboardSummary: BASE_URL + "/getDashboardSummary",
    GetTranslations: BASE_URL + "/GetTranslations",
    UpdateTranslations: BASE_URL + "/UpdateTranslations",
    JsonTranslations: BASE_URL + "/JsonTranslations",
    GetStatusApp: BASE_URL + "/GetStatusApp",
    GoogleAnalyticData: BASE_URL + "/GoogleAnalyticData",
    GetAudienceUserList: BASE_URL + "/GetAudienceUserList",
    AddUpdateOrDelete: BASE_URL + "/AddUpdateOrDelete",
    GetAllEntityFile: BASE_URL + "/GetAllEntityFile",
    GetEntityUserFiles: BASE_URL + "/GetEntityUserFiles",
    SearchEntityFileCategories: BASE_URL + "/SearchEntityFileCategories",
    SearchEntityFileFolder: BASE_URL + "/SearchEntityFileFolder",
    EntityFileSearch: BASE_URL + "/EntityFileSearch",
    DowloadEntityFile:BASE_URL+"/DownloadFile",
    GetAllUploadFileNotificationSent:BASE_URL+"/GetAllUploadFileNotificationSent",
    ReadUplaodFileNotification:BASE_URL+"/ReadUplaodFileNotification",
  },
  EliorApiKey: API_KEY ?? ""
}

export const EliorInternalName = {
  EmployeId: "EMPLOYEE ID",
  BirthDate: "BIRTH DATE",
  LastName: "LAST NAME",
  FirstName: "FIRST NAME",
  Category: "Category",
  EmployeeNumber: "EMPLOYEE NUMBER",
  PersonnalEmail: "PERSONAL EMAIL ADDRESS",
  PersonnalPhone: "PERSONAL MOBILE PHONE NUMBER",
  ManagedBy: "MANAGED BY"
}

export const EliorInternalValue = {
  ManageByUs: "app-06"
}

export const CheckAllow = {
  IsRetryAllowed: "isretryallowed",
  BlockedTime: "blockedtime",
  TryNumber: "tryNumber",
  IpIsLock: "ipIsLock"
}

export const LocalStorageKey = {
  EliorUpnKey: "EliorUPN",
  EliorThemeKey: "EliorTheme",
  EliorDownloadApp: "EliorDownloadApp",
  EliorUserKey: "user",
}
export const theming = {
  InternalName: {
    color: "--color-1",
    color2: "--color-2",
    gradientColor: "--gradient-1",
    pageBkgHeader: "--logo",
    pageBkg: "--page-bkg",
    pageBkg2: "--page-bkg-2",
    pageBkg3: "--page-bkg-3",
    pageBkg4: "--page-bkg-4",
    isError404: "--isError404"
  },
  Group: {
    color: "#65b32e",
    color2: "#c8d300",
    gradientColor: "linear-gradient(114.21deg, #65b32e 8.94%, #c8d300 122.39%)",
  },
  Country: {
    color: "#e60050",
    color2: "#ff0059",
    gradientColor: "linear-gradient(114.21deg, #e60050 8.94%, #ff0059  122.39%)",
  },
  Service: {
    color: "#007d96",
    color2: "#00a5cd",
    gradientColor: "linear-gradient(114.21deg, #007d96 8.94%, #00a5cd 122.39%)",
  },
  Ansamble: {
    color: "#b5b112",
    color2: "#d9d418",
    gradientColor: "linear-gradient(114.21deg, #b5b112 8.94%, #d9d418 122.39%)"
  },
  NorthAmerica: {
    color: "#e60050",
    color2: "#ff0059",
    gradientColor: "linear-gradient(114.21deg, #e60050 8.94%, #ff0059  122.39%)",
  },
  Alsacienne:{
    color:"#ee2722",
    color2:"#ee2722",
    gradientColor: "linear-gradient(114.21deg, #ee2722 8.94%, #ee2722  122.39%)",
  }
}

export const MessageSummaryLabel = {
  Sent: 1,
  Read: 2,
  Unread: 3
}

export const Cursor = {
  pointer: 'pointer',
  default: 'default'
}

export const configChartLegende = { display: true, position: 'bottom', align: 'center' }
export const chartType = { barChart: 'bar', pieChart: 'pie', doughnutChart: 'doughnut' }

export const paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
export const pageOptions = [10, 20, 50, 100]
export const legendPosition = { top: 'top', bottom: 'bottom', not: '' }
export const dimentions = {
  platform: 'platform',
  audienceId: 'audienceId',
  deviceCategory: 'deviceCategory',
  platformDeviceCategory: 'platformDeviceCategory',
  operatingSystem: 'operatingSystem',
  browser: 'browser'
}
