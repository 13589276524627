import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState, useContext, Suspense, lazy, Fragment } from "react";
import fr from 'date-fns/locale/fr';
import es from 'date-fns/locale/es';
import enUS from 'date-fns/locale/en-US';
import { registerLocale } from "react-datepicker";
import { useIsAuthenticated } from "@azure/msal-react";
import { MobileView } from "react-device-detect";
import { useTranslation } from "react-i18next";
import CacheBuster from 'react-cache-buster';

import Loader from "./components/Load/Loader";
import LoaderSuspense from "./components/Load/LoaderSuspense";
import DownloadAppCmp from "./components/DownloadAppCmp";
import { DatepickerContext, UserProfileContext } from "./services/context/index";
import useGaTracker from './components/useGaTracker';
import utilsServices from "./services/utils";
import { Routes } from "./components/Routes/Routes";
import packageJson from '../package.json';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const Header = lazy(() => import('./components/Header/Header'));
const Footer = lazy(() => import('./components/Footer'));

const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
var cs = process.env["REACT_APP_APPLICATIONINSIGHT_CS"];
var appInsights = new ApplicationInsights({
  config: {
    connectionString: cs,
    maxBatchInterval: 0,
    disableFetchTracking: false,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});

appInsights.loadAppInsights();


const App = () => {
  let _window: any;
  _window = window;
  //_window.document.querySelector("html").lang = utilsServices.getLngWithoutOption(utilsServices.getNavigatorLanguage());
  const isAuthent = useIsAuthenticated();
  const [isLoading, setIsLoading] = useState(true);

  const { addLanguage } = useContext(DatepickerContext);
  const { i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { isLoadingData, GetUserAD } = useContext(UserProfileContext);



  useEffect(() => {

    utilsServices.scrollTo(0);

  }, []);

  useEffect(() => {
    setIsLoading(false);
    if (isAuthent) {
      GetUserAD();
    }
  }, [isAuthent, history]);

  useEffect(() => {
    //Recuperation de la langue du navigateur
    i18n.changeLanguage(utilsServices.getNavigatorLanguage());

    //Gestion de la date(Calendrier) en fonction des langues
    registerLocale('fr', fr)
    addLanguage("fr")

    registerLocale('en', enUS)
    addLanguage("en")

    registerLocale('es', es)
    addLanguage("es")


  }, [i18n])

  useGaTracker();

  const WebWiew = () => {
    return (
      <Fragment>
        <MobileView>{location.pathname === "/" && <DownloadAppCmp />}</MobileView>
        <Header />
        <main className="idn-mainarea" role="main">
          <Suspense fallback={<Loader />} >
            <Routes />
          </Suspense>
        </main>
        <Footer />
      </Fragment>
    )
  }

  return (
    <AppInsightsErrorBoundary onError={() => <h1>I believe something went wrong</h1>} appInsights={reactPlugin}>
      <div>
        {
          isAuthent
            ? (!isLoadingData ? <Loader /> : <WebWiew />)
            : (isLoading ? <Loader /> : <WebWiew />)
        }
      </div>
    </AppInsightsErrorBoundary>
  );
};

export default withAITracking(reactPlugin, function WrappedApp(
  props: {
    // hubCon: any
  }
) {

  return (
    <Suspense fallback={<LoaderSuspense />} >
      <CacheBuster
        currentVersion={packageJson.version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
      >
        <App />
      </CacheBuster>
    </Suspense>
  );
});
