import styled from "styled-components"
export const InformeUserLi = styled.span`
    padding:0;
    margin:0;
    text-align:center;
    font-size:12px;
    background:red;
    color:white;
    width:15px;
    height:15px;
    line-height:15px;
    border-radius:10px;
    position:absolute;
    right:-5px;
    top:-4px;
`

export const InformeUserDiv = styled.div`
    padding:2px;
    margin:0;
    text-align:center;
    font-size:12px;
    background:red;
    color:white;
    width:100%;
    `

export const CountMessage = styled.span`
    padding:0;
    margin:0;
    text-align:center;
    font-size:9px;
    background:black;
    color:white;
    width:15px;
    height:15px;
    line-height:10px;
    border-radius:10px;
    position:absolute;
    right:-2px;
    top:-4px;
    padding:3px;
`;

export const ButtonFilter = styled.button`
font-size: 12px;
height: 20px;
line-height: 20px;
padding: 0 10px;
`;

export const styleWidth = { width: "100%" }
export const styleFlex = { display: 'flex', justifyContent: "space-between", marginBottom: '20px' }
export const styleModalChart = { width: '75%', height: '600px' }
export const styleSizeChart = { width: '100%', height: '380px' }
export const styleReSizeChart = { width: '100%', height: '100%' }
export const chartFont = { size: 14, family: "Elior" }
