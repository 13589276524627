
import { useTranslation } from "react-i18next";
import LoaderContent from "../Load/LoaderContent";
import { useEffect, useState } from "react";
import HeaderItem from "../Header/HeaderItem";
import APIServices from "../../services/APIServices";
import FolderdetailComponent from "./FolderdetailComponent";
import { ScrollPanel } from 'primereact/scrollpanel';
import React from "react";

export default function UserEntityFileList(){

    const { t } = useTranslation();
    // const { appData, isLoading, isError } = useApplication();
    const [entityUserFile, setEntityUserFile] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        GetAllEntityFile();
    },[])


    useEffect(() => {
        if (entityUserFile.length > 0) {
            setIsLoading(false);
        }
    }, [entityUserFile])

    async function GetAllEntityFile() {
        return new Promise(async (resolve, reject) => {
            try {
                var entityUserFiles = await APIServices.GetEntityUserFiles();
                setEntityUserFile(entityUserFiles);
                resolve(entityUserFiles);
            } catch (e) {
                reject(e)
            } finally {
                setIsLoading(false);
            }
        })
    }

    return (
        <>
           {isLoading && <LoaderContent />}
            {
                entityUserFile && entityUserFile.map((data:any,key:any) =>{
                    return <section key={key} className={"idn-page-content IdnPageContent--overflow"}>
                            <HeaderItem
                                title={data.categoryName}
                                className={"idn-page-content IdnPageContent--overflow"}
                            />
                            <FolderdetailComponent detailFolderData={data.categoryFileDetails}/>
                    </section>
                })
           }
        </>
    );
}

