const SET_PUSH_NOTIFICATION = "push_notif_com/set";

export const setPushNotification = (subscibe: any) => ({
    type: SET_PUSH_NOTIFICATION,
    payload: subscibe
})

export default function pushNotificationReducer(state = {}, action: any) {
    if (action.type === SET_PUSH_NOTIFICATION) {
        return action.payload
    }

    return state;
}