import { BsChevronUp, BsChevronDown } from "react-icons/bs";
import HeaderProfile from "./HeaderProfile";

const HeaderItem = (
    { title, setShow, show, showIcon = false, className, buttonBack }: {
        title?: any,
        setShow?: any,
        show?: boolean,
        showIcon?: boolean,
        className?: any,
        buttonBack?: any,
    }
) => {

    return (
        <div onClick={() => { showIcon && setShow(!show) }}>
            <HeaderProfile
                className={className}
                title={<div className="idn-page-title__text">
                    <div style={{ display: "flex", width: "100%" }}>
                        {buttonBack}{title}
                    </div>
                    {
                        showIcon &&
                        <div>
                            {show ? <BsChevronUp /> : <BsChevronDown />}
                        </div>
                    }

                </div>}
            />
        </div>
    )
}

export default HeaderItem;