import { useTranslation } from "react-i18next";
import { FaAngleRight } from "react-icons/fa";
import Tooltip from "../Tooltip";

const Application = (props: {
  appName: string;
  appDescription: string;
  appUrl: string;
  appImageUrl: string;
}) => {

  const { t } = useTranslation();

  return (
    <article className="idn-card" >
      <div className="idn-card__header">
        <h2 className="idn-card__header-title">{props.appName}</h2>
        {props.appImageUrl && (
          <div className="idn-card__header-icon">
            <img src={props.appImageUrl} alt={props.appName} />
          </div>
        )}
      </div>
      <Tooltip
        position={"bottom"}
        title={props.appDescription}
      >
        <div className="idn-card__content">{props.appDescription}</div>
      </Tooltip>
      <Tooltip
        position={"bottom"}
        title={props.appName}
      >
        <div className="idn-card__footer" onClick={() => window.open(props.appUrl, "_blank")}>
          <FaAngleRight />
        </div>
      </Tooltip>
    </article>
  );
};

export default Application;
