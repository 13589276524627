/**Global modification or improvemnt method */
export default function DecoratorPattern() {
    var string: any = String;
    string.prototype.replaceAll = function (searchValue: string | RegExp, replaceValue: string) {
        if (typeof searchValue === "object") {
            return this.replace(searchValue, replaceValue);
        } else {
            return this.replace(new RegExp("[" + searchValue + "]", "gi"), replaceValue);
        }
    }
}