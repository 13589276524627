const SET_NUMBER_NOTIFICATION = "number_message/set";

export const setNotificationNumber = (nbr: number) => ({
    type: SET_NUMBER_NOTIFICATION,
    payload: nbr
})

export default function notificationNumberReducer(state = {}, action: any) {
    if (action.type === SET_NUMBER_NOTIFICATION) {
        return action.payload
    }

    return state;
}