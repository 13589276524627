const SET_MESSAGE_CRUD_DISPLAY = "messagecrud/set";

export const setMessageId = (message: 0) => ({
    type: SET_MESSAGE_CRUD_DISPLAY,
    payload: message
});

export default function messageIdReducer(state = 0, action: any) {
    if (action.type === SET_MESSAGE_CRUD_DISPLAY) {
        return action.payload;
    }
    return state;
}