import styled from "styled-components";
import errorLoading from "../../assets/images/error/errorLoading.png";
import { useTranslation } from "react-i18next";
import { ErrorLoadingContext } from "../../services/context";
import { useContext } from "react";

export default function ErrorLoading(
    props: {
        setRefresh?: any
    }
) {

    const { addError, errorList, removeError } = useContext(ErrorLoadingContext);
    const { t } = useTranslation();
    const ErrorLoading = styled.div`
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
    `;

    const loadData = () => {
        console.log(errorList);

        for (var err of errorList) {
            err.status = true;
            console.log("just un test")
            console.log(err)
            addError(err);
        }

        //props.setRefresh(true);
    }

    return (
        <ErrorLoading>
            <div>
                <img width={50} height={50} src={errorLoading} alt="errorloading" />
            </div>
            <div className="mb-2">
                {t("ErrorLoading.Label")}
            </div>
            <button className="idn-button" onClick={() => { loadData() }}>
                {t("ErrorLoading.ButtonTitle")}
            </button>
        </ErrorLoading>
    )
}