import { useState } from "react";
import { useTranslation } from "react-i18next";
import iphoneHomeScreen from "../assets/images/iphone-share-icon.png";
import androidHomeScreen from "../assets/images/android-menu-icon.png";
import { isIOS } from "react-device-detect";
import { LocalStorageKey } from "../services/constants";
import { BsXLg } from "react-icons/bs";

const DownloadAppCmp = () => {
  const [displayDownloadCmp, setDisplayDownloadCmp] = useState(
    localStorage.getItem(LocalStorageKey.EliorDownloadApp) === null
      ? true
      : false
  );

  const { t } = useTranslation();
  const showHideComponent = () => {
    setDisplayDownloadCmp(false);
    localStorage.setItem(LocalStorageKey.EliorDownloadApp, "false");
  };
  return displayDownloadCmp ? (
    <div id="idn-download">
      <div className="idn-section">
        <div id="idn-download-text">
          {isIOS ? (
            <div>
              <div id="idn-download-title">
                {t("DownloadAppCmp.DownloadAppCmpTitle")}
              </div>
              <div id="idn-download-text">
                {t("DownloadAppCmp.DownloadAppCmpIphoneMsg01")}{" "}
                <img src={iphoneHomeScreen} alt="" />{" "}
                {t("DownloadAppCmp.DownloadAppCmpIphonedMsg02")}
              </div>
            </div>
          ) : (
            <div>
              <div id="idn-download-title">
                {t("DownloadAppCmp.DownloadAppCmpTitle")}
              </div>
              <div id="idn-download-text">
                {t("DownloadAppCmp.DownloadAppCmpAndroidMsg01")}{" "}
                <img src={androidHomeScreen} alt="" />{" "}
                {t("DownloadAppCmp.DownloadAppCmpAndroidMsg02")}
              </div>
            </div>
          )}
        </div>
        <button aria-label='close'
          className="idn-icon-delete"
          onClick={() => showHideComponent()}
        >
          <BsXLg />
        </button>
      </div>
    </div>
  ) : (
    <div />
  );
};

export default DownloadAppCmp;
