import { FetchApi } from "./FetchApi";


export class PublicIpService {
    private URL: string = "https://ip4.seeip.org/json";
    private _fetchApi: FetchApi;

    constructor() {
        this._fetchApi = new FetchApi();
    }

    async getInfos() {
        var data = await this._fetchApi.getExcludeAzfunc(this.URL);
        console.log(`data `, data)
        return data;
    }
}