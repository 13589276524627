import { combineReducers, createStore } from 'redux';
import receivedMessagesReducer from './features/receivedMessage';
import messageDisplayReducer from './features/displayMessage';
import unReadMessagesReducer from './features/unReadMessage';
import notificationNumberReducer from './features/notificationMessageNumber';
import pushNotificationReducer from './features/pushNotification';
import messageIdReducer from './features/messageCrud';
import totalItemsReducer from './features/totalItemsMessages';

const reducer = combineReducers({
    receivedMessage: receivedMessagesReducer,
    displayMessage: messageDisplayReducer,
    unReadMessage: unReadMessagesReducer,
    notificationNumber: notificationNumberReducer,
    pushNotification: pushNotificationReducer,
    messageId: messageIdReducer,
    totalItems: totalItemsReducer
});

const __WINDOW: any = window;
const reduxDevTools = __WINDOW.__REDUX_DEVTOOLS_EXTENSION__ && __WINDOW.__REDUX_DEVTOOLS_EXTENSION__();

const store = createStore(reducer, reduxDevTools);

export default store;