import * as rdd from 'react-device-detect';
import { Config } from "./constants";
import browserID from 'browser-id';

export class FetchApi {

    public _headers: any = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Headers': 'X-Requested-With',
        'x-functions-key': Config.EliorApiKey,
        "Device": JSON.stringify([
            rdd.osName,
            rdd.osVersion,
            rdd.browserName,
            rdd.browserVersion
        ]),
        "browserID": browserID()
    };




    constructor() {

    }

    public getUpn() {
        let saveUser: any = localStorage.getItem("user");
        let _saveUser = saveUser ? JSON.parse(saveUser) : {};
        let upn = "";
        try {
            if (_saveUser.O365[0].UPN)
                upn = _saveUser.O365[0].UPN;
        } catch (e) {

        }

        return upn;
    }

    public headerWithUpn() {
        let upn = this.getUpn();
        return { ...this._headers, upn };
    }

    private addUpn() {
        let upn = this.getUpn();
        this._headers = { ...this._headers, upn }
    }



    async getExcludeAzfunc(url: string) {
        return fetch(url)
            .then(res => res.json())
            .then(res => res)
            .catch(err => {
                console.log(`get Error : ${err}`);
                return err;
            })
    }

    async postWithUpn(params: any) {
        this.addUpn();
        return await this.post(params.url, params.body);
    }


    async putWithUpn(params: any) {
        this.addUpn();
        return await this.put(params.url, params.body);
    }

    async post(url: string, body?: any) {

        return new Promise(async (resolve, reject) => {
            fetch(url,
                body ? {
                    method: "POST",
                    headers: this._headers,
                    body: JSON.stringify(body)
                } : {
                    method: "POST",
                    headers: this._headers
                }
            )
                .then(async (res) => {
                    if (res.status === 500) {
                        reject(res)
                    }
                    resolve(res)
                })
                .catch(err => {
                    console.log(`Error : ${err}`);
                    reject(err)
                })
        });
    }

    async put(url: string, body?: any) {

        return new Promise(async (resolve, reject) => {
            fetch(url,
                body ? {
                    method: "PUT",
                    headers: this._headers,
                    body: JSON.stringify(body)
                } : {
                    method: "PUT",
                    headers: this._headers
                }
            )
                .then(async (res) => {
                    if (res.status === 500) {
                        reject(res)
                    }
                    resolve(res)
                })
                .catch(err => {
                    console.log(`Error : ${err}`);
                    reject(err)
                })
        });
    }

    async patch(url: string, body?: any) {
        return new Promise(async (resolve, reject) => {
            fetch(url,
                body ? {
                    method: "PATCH",
                    headers: this._headers,
                    body: JSON.stringify(body)
                } : {
                    method: "PATCH",
                    headers: this._headers
                }
            )
                .then(async (res) => {
                    if (res.status === 500) {
                        reject(res)
                    }
                    resolve(res)
                })
                .catch(err => {
                    console.log(`Error : ${err}`);
                    reject(err)
                })
        });
    }

    async getMethodWithUpn(params: any) {
        this.addUpn();
        return await this.getMethod(params.url, params.body);
    }

    async getMethod(url: string, body?: any) {
        return new Promise(async (resolve, reject) => {
            fetch(url,
                body ? {
                    method: "POST",
                    headers: this._headers,
                    body: JSON.stringify(body)
                } : {
                    method: "GET",
                    headers: this._headers
                }
            )
                .then(async (res) => {
                    if (res.status === 500) {
                        reject(res)
                    }
                    resolve(res)
                })
                .catch(err => {
                    console.log(`Error : ${err}`);
                    reject(err)
                })

        });
    }

    async get(url: string) {
        return new Promise(async (resolve, reject) => {
            fetch(url, {
                method: "GET"
            }
            )
                .then(async (res) => {
                    if (res.status === 500) {
                        reject(res)
                    }
                    resolve(res)
                })
                .catch(err => {
                    console.log(`Error : ${err}`);
                    reject(err)
                })

        });
    }

    async delete(url: string) {
        return new Promise(async (resolve, reject) => {
            fetch(url,
                {
                    method: "DELETE",
                    headers: this._headers
                }
            )
                .then(async (res) => {
                    if (res.status === 500) {
                        reject(res)
                    }
                    resolve(res)
                })
                .catch(err => {
                    console.log(`Error : ${err}`);
                    reject(err)
                })

        });

    }
}