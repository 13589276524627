
import GlobalLoad from "./GlobalLoad";

const LoaderSuspense = () => {

    return (
        <div id="idn-app-loader" className="idn-loader">
            <GlobalLoad title="" />
        </div>
    );
};

export default LoaderSuspense;