import React, {useEffect, useState } from "react";
import { Tree } from "primereact/tree";
import { classNames } from 'primereact/utils';
import styled from "styled-components";
import { Button } from 'primereact/button';
import utilsServices from "../../services/utils";
import { useTranslation } from "react-i18next";
import { Badge } from 'primereact/badge';

const _buttonStyle = { width: "25px", height: "25px", marginLeft: "10px" };

export default function FolderdetailComponent(props:{detailFolderData:any}){

    const [nodes, setNodes] = useState<any>([]);
    const [expandKeys, setExpandKeys] = useState<any>([]);

    const { t } = useTranslation();
    
    useEffect(() => {

        if (props.detailFolderData) {
            
            let _nodeDatas :any = [];
            for (let i = 0; i< props.detailFolderData.length; i++){
                let _countChildrenData: any = [];
                for (let j = 0; j< props.detailFolderData[i].fileDetails.length; j++){
                    let children = {
                        key: `${i}-${j}`,
                        label:props.detailFolderData[i].fileDetails[j].fileName,
                        url:props.detailFolderData[i].fileDetails[j].fileLink,
                        icon:utilsServices.getFileIcon(props.detailFolderData[i].fileDetails[j].fileType),
                        folder : props.detailFolderData[i].fileDetails[j].folder,
                        folderId : props.detailFolderData[i].fileDetails[j].folderId
                    }
                    _countChildrenData.push(children);
                }
                let nodeData = {
                    key: `${i}`,
                    label: props.detailFolderData[i].folder === "" ? t('applicationList.EmptyCategoryFolder'): props.detailFolderData[i].folder,
                    children:_countChildrenData,
                    countChildren: _countChildrenData.length
                }
                _nodeDatas.push(nodeData);    
            }
            setNodes(_nodeDatas);
        }
    }, [props.detailFolderData]);

    useEffect(() => {

        if(nodes.length > 0){
            const fecthNodeKey = (nodes:any, keys:any = []) =>{
                nodes.forEach((node:any) => {              
                    if(node.label === t('applicationList.EmptyCategoryFolder')){
                        keys.push(node.key)
                    }
                });

                return keys;
            }
            setExpandKeys(fecthNodeKey(nodes));
        }

    },[nodes])

    const HandleDownload = (fileUrl:string, fileName:string) =>{
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.parentNode?.removeChild(fileLink);  
    }

    const nodeTemplate = (node:any, options:any) => {
        let label = <b className="p-overlay-badge">{node.label}<Badge  value={`(${node.countChildren})`}></Badge></b>;
        if (node.url) {
            label = <span> <span><a href={node.url} style={{color:"#e60050", fontSize:"18px", textDecoration:"none"}} target="_blank">{node.label}
            </a></span>
            <span>
            <Button icon="pi pi-download" className="p-button-rounded p-button-success mr-2"
                    onClick={() => HandleDownload(node.url,node.label)}
                    style={{ ..._buttonStyle, marginRight: '5px' }}
                    tooltip={t('applicationList.DownloadFile')}
                />
            </span>
            </span>;
        }
        return <span className={options.className}>{label}</span>;
    }

    const togglerTemplate = (node:any, options:any) => {
        if (!node) {
            return;
        }
        let expanded = options.expanded;
        const iconClassName = classNames('p-tree-toggler-icon pi pi-fw', {
            'pi-folder': !expanded,
            'pi-folder-open': expanded
        });

        return (
            <button id={node.label} type="button" className="p-tree-toggler p-link" tabIndex={-1} onClick={options.onClick}>
                <span className={iconClassName} aria-hidden="true"></span>
            </button>
        );
    };

    return (
        <div className="flex justify-content-center">
            <Tree expandedKeys={expandKeys} value={nodes} onToggle={(e:any) => setExpandKeys(e.value)} nodeTemplate={nodeTemplate} togglerTemplate={togglerTemplate} className="w-full md:w-30rem" />
        </div>
    )
}