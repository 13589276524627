import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Moment from "moment";
//@ts-ignore
import ReactHtmlParser from "react-html-parser";
import { FcServices } from "react-icons/fc";

import { useUpdateTitle } from "../../services/hooks";
import utilsServices from "../../services/utils";

export default function MaintenancePage(props: {
    date: any
}) {
    const { setDocumentTitle } = useUpdateTitle();

    const { t } = useTranslation();
    useEffect(() => {
        setDocumentTitle("Maintenance");
    });

    return (
        <div className="idn-error404 mb-5">
            <div className="idn-error404-page">
                <h1 className="idn-error404-page__header mb-5">
                    <FcServices />
                </h1>
                <p className="idn-error404-page__title">
                    {t("ErrorLoading.PageMaintenanceTitle")}
                </p>
                <p className="idn-error404-page__desc idn-error404-page--font-size">
                    {props.date && Moment.utc(props.date).toString() !== 'Invalid date' ? ReactHtmlParser(t("ErrorLoading.PageMaintenanceInfosWithDate", { date: utilsServices.convertDateUtcToLocalTimeZone(props.date) }))
                        : ReactHtmlParser(t("ErrorLoading.PageMaintenanceInfosWith"))}
                    <div className="idn-error404-page__desc  mt-3">{t("ErrorLoading.PageMaintenanceSupport")}</div>
                </p>
            </div>
        </div>

    )
}