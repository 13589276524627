import Tooltip from "../Tooltip";
import { BsQuestionCircle } from "react-icons/bs";
import { QuickLinkModel } from "../../models/QuickLinkModel";


const QuickLink = (
    props: {
        quickLinks: QuickLinkModel[],
        cat: any
    }
) => {


    return (
        <li className="list-group-item">
            <ul className="me-auto quickLinkBody">
                <li className="fw-bold quickLinkHeader">{props.cat}</li>
                <ul className="quickLinkList">
                    {props.quickLinks &&
                        props.quickLinks.filter(q => q.categoryLabel === props.cat).map((quickLink, j) => {
                            return (
                                <li key={`li${quickLink.labelName}-${j}`}>

                                    <span className="li__flex">

                                        {
                                            quickLink.link ?
                                                <Tooltip title={quickLink.descriptions} position="left" trigger="hover">
                                                    <a href={quickLink.link.toString().trim() == "" ? "#" : quickLink.link.toString().trim()} target="_blank">
                                                        <span>
                                                            {quickLink.labelName}
                                                        </span>
                                                    </a>
                                                </Tooltip> : <span className="quickLinkList-li-nolink">
                                                    {quickLink.labelName}
                                                </span>
                                        }
                                        {
                                            quickLink.attachmentLink ?
                                                <Tooltip title={quickLink.attachmentLink} position="left" trigger="hover">
                                                    <a href={quickLink.attachmentLink.toString().trim() == "" ? "#" : quickLink.attachmentLink.toString().trim()} target="_blank">
                                                        <BsQuestionCircle />
                                                    </a>
                                                </Tooltip> : <span className="quickLinkList-li-nolink">
                                                </span>
                                        }

                                    </span>


                                </li>
                            )
                        })
                    }
                </ul>
            </ul>
        </li>
    );
}

export default QuickLink;