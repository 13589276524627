const SET_MESSAGE_DISPLAY = "message/set";

export const setDisplayMessage = (message: {}) => ({
    type: SET_MESSAGE_DISPLAY,
    payload: message
});

export default function messageDisplayReducer(state = {}, action: any) {
    if (action.type === SET_MESSAGE_DISPLAY) {
        return action.payload;
    }
    return state;
}