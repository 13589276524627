import { useTranslation } from 'react-i18next';
import GlobalLoad from './GlobalLoad';
import { Suspense } from 'react';

const LoaderApp = () => {
  const { t } = useTranslation();

  return (
    <div id="idn-app-loader" className="idn-loader">
      <GlobalLoad title={t('loader.Loading')} />
    </div>
  );
};

const Loader = () => {
  return (
    <Suspense fallback={<div>t('loader.Loading')</div>}>
      <LoaderApp />
    </Suspense>
  );
};

export default Loader;
