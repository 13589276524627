import { useTranslation } from "react-i18next";
import GlobalLoad from "./GlobalLoad";

const LoaderContent = () => {
    const { t } = useTranslation();

    return (
        <div id="idn-app-loader-page" className="idn-loader">
            <GlobalLoad
                title={t("loader.Loading")}
                imgLoading={false} />
        </div>
    );
};

export default LoaderContent;